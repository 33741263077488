import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "vuefire";

function lazyView(view: string) {
  return () => import(`../pages/${view}.vue`);
}
const StorePage = () => import("../pages/StorePage.vue");
const StoreDetailPage = () => import("../pages/StoreDetailPage.vue");
const StoreSettingsPage = () => import("../pages/StoreSettingsPage.vue");
const StoreSettingsSweepPage = () =>
  import("../pages/StoreSettingsSweepPage.vue");
const DashboardPage = () => import("../pages/DashboardPage.vue");
const StoreSettingsSplitConfigurationPage = () =>
  import("../pages/StoreSettingsSplitConfigurationPage.vue");

const StoreSettingsTerminalPage = () =>
  import("../pages/StoreSettingsTerminalPage.vue");
const StoreSettingsLogo = () => import("../pages/StoreSettingLogo.vue");
const StoreSettingsPasscodes = () =>
  import("../pages/StoreSettingsPasscodesPage.vue");
const StoreSettingsEnableStandalone = () =>
  import("../pages/StoreSettingsEnableStandalone.vue");
const StoreSettingsSurchargePage = () =>
  import("../pages/StoreSettingsSurchargePage.vue");
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Intro",
    component: DashboardPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "home-redirect",
        redirect: (_to) => ({ name: "HomePage" }),
      },
      {
        path: "dashboard",
        name: "HomePage",
        component: lazyView("HomePage"),
      },

      {
        path: "help",
        name: "HelPage",
        component: lazyView("HelpPage"),
      },
      {
        path: "/payments",
        name: "PaymentsPage",
        component: lazyView("PaymentsPage"),
      },
      {
        path: "/stores",
        name: "StoresPage",
        component: lazyView("StoresPage"),
      },
      {
        path: "/stores/:store/settings",
        name: "UserStoreSettingsPage",
        component: lazyView("SettingsPage"),
      },
      {
        path: "/merchants/:merchant/payouts",
        name: "PayoutsPage",
        component: lazyView("MerchantPayoutPage"),
      },
      {
        path: "/merchants/:merchant/new-order",
        name: "NewOrder",
        component: lazyView("NewOrderPage"),
      },
      {
        path: "/merchants/:merchant/orders",
        name: "Orderpage",
        component: lazyView("OrderPage"),
      },
      {
        path: "/merchants/:merchant/stores/:store",
        name: "StorePage",
        component: StorePage,
        children: [
          {
            path: "",
            name: "store-redirect",
            redirect: (_to) => ({ name: "StoreDetailPage" }),
          },

          {
            path: "payments/:date",
            name: "StoreDetailPage",
            component: StoreDetailPage,
          },
          {
            path: "settings",
            name: "StoreSettingsPage",
            component: StoreSettingsPage,
            children: [
              {
                path: "",
                name: "settings-redirect",
                redirect: (_to) => ({ name: "StoreSettingsTerminalPage" }),
              },
              {
                path: "sweep",
                name: "StoreSettingsSweepPage",
                component: StoreSettingsSweepPage,
              },
              {
                path: "terminal",
                name: "StoreSettingsTerminalPage",
                component: StoreSettingsTerminalPage,
                props: true,
              },
              {
                path: "surcharge",
                name: "StoreSettingsSurchargePage",
                component: StoreSettingsSurchargePage,
                props: true,
              },
              {
                path: "split-configuration",
                name: "StoreSettingsSplitConfigurationPage",
                component: StoreSettingsSplitConfigurationPage,
              },
              {
                path: "logo",
                name: "StoreSettingsLogo",
                component: StoreSettingsLogo,
              },
              {
                path: "passcodes",
                name: "StoreSettingsPasscodes",
                component: StoreSettingsPasscodes,
              },
              {
                path: "standalone",
                name: "StoreSettingsEnableStandalone",
                component: StoreSettingsEnableStandalone,
              },
            ],
          },
        ],
      },

      {
        path: "/new-store",
        name: "NewVenuePage",
        component: lazyView("NewVenuePage"),
      },
    ],
  },
  {
    path: "/login",
    name: "LoginPage",
    component: lazyView("LoginPage"),
  },
  {
    path: "/email-signin",
    name: "EmailSignPage",
    component: lazyView("EmailSigninPage"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  // routes with `meta: { requiresAuth: true }` will check for
  // the users, others won't
  if (to.meta.requiresAuth) {
    const currentUser = await getCurrentUser();
    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: "/login",
        query: {
          // we keep the current path in the query so we can
          // redirect to it after login with
          // `router.push(route.query.redirect || '/')`
          redirect: to.fullPath,
        },
      };
    }
  }
});

export default router;
